import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import * as MonitoringActions from './monitoring.action';
import { catchError, map, of, switchMap, withLatestFrom } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { initPath } from "../../../pages/membership/functions/decorators/init-path.decorator";
import { IPageView } from "../../../pages/monitoring/interfaces/PageView.interface";
import { AppState } from "../state.interface";
import { selectMonth } from "./monitoring.selector";

@Injectable()
export class MonitoringEffects {

    @initPath('monitoring/pv')
    url: any;

    getPv$ = createEffect(() => 
        this.actions.pipe(
            ofType(MonitoringActions.pvAction),
            withLatestFrom(this.store.pipe(select(selectMonth))),
            switchMap(([action, month]) => {
                // console.log('값', month.toString());
                
                const params = new HttpParams().set('date', month.toString());
                return this.http.get<IPageView[]>(this.url, {params}).pipe(
                    map((pvData => {
                        return MonitoringActions.pvSuccessAction({
                            pvData
                        })
                    })),
                    catchError( error => {
                        // console.log('에러 이펙트 호출');
                        return of(MonitoringActions.pvFailureAction({
                            error
                        }))
                    })
                )
            })
        )
    )

    constructor(
        private actions: Actions,
        private http: HttpClient,
        private store: Store<AppState>
    ){}
}