import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { EnvironmentName } from '../environments/enum/environmentname.enum';
import { routes } from './routes/prod.routes';
import { devRoutes } from './routes/dev.routes';


const routerMap = new Map<EnvironmentName, Routes>([
  [EnvironmentName.Production, routes],
  [EnvironmentName.Development, devRoutes],
  [EnvironmentName.Staging, devRoutes],
  [EnvironmentName.Local, devRoutes],//로컬도 devroutes 같이 쓰게~
  // [EnvironmentName.Common, devRoutes]
])

const loadRouter = (env:EnvironmentName) => {
  return routerMap.get(env) as Routes;
}

@NgModule({
  imports: [RouterModule.forRoot(loadRouter(environment.environmentName))],
  exports: [RouterModule]
})
export class AppRoutingModule { }
