import { Routes } from '@angular/router';
import { canActivate } from '../../../Modules/core/guards/authguard.guard';
import { roleGuard } from '../../../Modules/core/guards/roleguard.guard';
import { RoleName } from '../../../Modules/pages/membership/util/enum/role-name.enum';

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/membership',
        pathMatch: 'full',
    },
    {
        path: 'main',
        loadChildren: () => import('../../../Modules/pages/main/main.module').then(m => m.MainModule),
        canActivate: [canActivate]
    },
    {
        path: 'chatbot',
        loadChildren: () => import('../../../Modules/pages/chatbot/chatbot.module').then(m => m.ChatbotModule),
        canActivate: [canActivate]
    },
    {
        path: 'history',
        loadChildren: () => import('../../../Modules/pages/history/history.module').then(m => m.HistoryModule),
        canActivate: [canActivate]
    },
    {
        path: 'membership',
        loadChildren: () => import('../../../Modules/pages/membership/membership.module').then(m => m.MembershipModule),
    },
    {
        path: 'logic',
        loadChildren: () => import('../../../Modules/pages/chatflow/chatflow.module').then(m => m.ChatflowModule),
        canActivate: [canActivate]
    },
    {
        path: 'category-manage',
        loadChildren: () => import('../../../Modules/pages/category-manage/category-manage.module').then(m => m.CategoryManageModule),
        canActivate: [canActivate]
    },
    {
        path: 'monitoring',
        loadChildren: () => import('../../../Modules/pages/monitoring/monitoring.module').then(m => m.MonitoringModule),
        canActivate: [canActivate, roleGuard],
        data: {
            requiredRoles: [RoleName.SuperAdmin]
        }
    },
    {
        path: '**',
        redirectTo: '/chatbot'
    }
];
